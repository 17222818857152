<template>
  <div class="colapseShow container" :class="{white}">
    <div class="row" :class="{reverse: type !== 'left'}">
      <div class="show-avator col-sm-12 col-md-6 flex">
        <div class="flex1 flex" v-for="(currentTarget, index) in info.items" :key="index" v-show="currentTarget.active">
          <img style="object-fit:contain" v-if="currentTarget.type == 'img'" :src="currentTarget.src">
          <video controls v-if="['mp4', 'm4v','mov'].includes(currentTarget.type)" :autoplay="index==0 ? autoplay: false" loop preload
            muted poster="../../assets/nxt/poster.png" playsinline webkit-playsinline class="mw-100 flex1">
            <source :src="currentTarget.src" :type="`video/${currentTarget.type}`">
            Your browser does not support the HTML5 video.
          </video>
        </div>
      </div>
      <ul class="show-infos col-sm-12 col-md-6">
        <li v-for="(item, index) in info.items" :key="index" :class="{active: item.active}"
          @mouseenter="handleMouse(index)">
          <h3>{{item.title}}</h3>
          <p>{{item.desc}}</p>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
  export default {
    name: "",
    props: {
      autoplay: {
        default: false,
        type: Boolean
      },
      info: {
        default: () => ({}),
        type: Object
      },
      type: {
        default: 'left',
        type: String
      },
      white: {
        default: false,
        type: Boolean
      }
    },
    computed: {
      currentTarget() {
        return this.info.items.find(el => el.active)
      }
    },
    methods: {
      handleMouse(index) {
        this.info.items.forEach((el, idx) => {
          el.active = index == idx
        })
      }
    }
  }
</script>

<style lang="scss">
  .colapseShow .row,
  .show-avator {
    height: 310px;
    overflow: hidden;
  }

  .flex1 {
    flex: 1
  }

  .show-avator {
    img {
      max-width: 100%;
    }
  }
  .colapseShow {
    margin: 150px auto;
  }
  .reverse {
    flex-direction: row-reverse
  }

  ul {
    list-style: none;
  }

  div.white .show-infos {
    color: #fff;

    li.active {
      background-color: rgba(255, 255, 255, .1);
      box-shadow: 0 2px 12px 0 rgba(0, 0, 0, .1);
    }
  }

  .show-infos {
    color: #464C56;

    h3 {
      font-size: 20px;
    }

    li {
      transition: all .3s ease-in-out;
      cursor: pointer;
      padding: 16px 14px;
      margin: 10px auto;
    }

    li p {
      font-size: 14px;

      display: none
    }

    li.active {
      border-radius: 2px;
      background-color: rgba(48, 72, 129, .1);
      box-shadow: 0 2px 12px 0 rgba(0, 0, 0, .1);

      p {
        display: block
      }
    }
  }

  @media (max-width: 770px) {
    div.colapseShow .row {
      height: auto;

    }
  }
</style>